<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="size"
    viewBox="0 -960 960 960"
    :width="size"
  >
    <path
        class="icon"
        :fill="color"
      d="M160-80q-33 0-56.5-23.5T80-160v-360q0-33 23.5-56.5T160-600h80v-200q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v360q0 33-23.5 56.5T800-360h-80v200q0 33-23.5 56.5T640-80H160Zm0-80h480v-280H160v280Zm560-280h80v-280H320v120h320q33 0 56.5 23.5T720-520v80Z"
    />
  </svg>
  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="size"
    viewBox="0 -960 960 960"
    :width="size"
  >
    <path
    class="icon"
      d="M320-320h480v-400H320v400Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm160-720v480-480Z"
    />
  </svg> -->
</template>

<script>
export default {
  props: {
    size: {
      type: String | Number,
      required: false,
      default: 24,
    },
    color: {
      type: String,
      required: false,
      default: '#5e5873'
    }
  },
};
</script>

<style scoped>
.dark-layout .icon{
    fill: #d0d2d3;
}
</style>