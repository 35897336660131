<template>
  <component :is="sprintData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="sprintData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.errorSprint") }}
        <b-link class="alert-link" :to="{ name: 'apps-team-list' }">
          {{ $t("message.listOfSprint") }}
        </b-link>
        {{ $t("message.forOtherSprint") }}
      </div>
    </b-alert>
    <b-overlay
      variant="transparent"
      opacity="1.00"
      blur="6px"
      :show="showOverlay"
      rounded="sm"
      no-wrap
    ></b-overlay>
    <b-card no-body class="sprint-preview-card">
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div>
            <div class="logo-wrapper">
              <h2 class="invoice-logo">
                {{ sprintData.subject }}<br />

                <b-badge
                  style="font-size: small !important"
                  :variant="statusColor(sprintData.status)"
                >
                  {{ sprintData.status }}
                </b-badge>
              </h2>
            </div>
          </div>

          <!-- Header: Right Content -->
          <div>
            <b-dropdown
              id="dropdown-1"
              class="float-right"
              size="sm"
              variant="link"
              no-caret
              v-if="
                restrictions('button_edit_sprint') ||
                restrictions('button_delete_sprint')
              "
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-if="
                  restrictions('button_edit_sprint') && sprintData.id != null
                "
                @click="
                  $router.push({
                    name: 'apps-sprint-edit',
                    params: { id: sprintData.id },
                  })
                "
                >{{ $t("message.buttons.edit") }}</b-dropdown-item
              >
              <b-dropdown-item
                v-if="restrictions('button_delete_sprint')"
                v-b-modal.modal-delete-confirmation
                >{{ $t("message.buttons.delete") }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
        <br />

        <b-card-text>
          <span v-html="sprintData.description"></span
        ></b-card-text>
      </b-card-body>

      <b-card-body class="sprint-padding pb-0">
        <div v-if="sprintData.project">
          <b-row>
            <b-col md="8" v-if="sprintData.tasks.length > 0">
            <strong class="d-flex mb-0" style="gap: 0.25rem">
              {{ $t("message.projects.project") }}
            </strong>
              <a
                 class="d-flex mb-0"
                @click="openProjectbyId(sprintData.project.id)"
                style="color: #5ca6de; gap: 0.25rem"
                >{{ sprintData.project.name }}
                <IconLinkInternal size="12" color="#5ca6de"/>
                <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
              </a>
            </b-col>
            <b-col md="9" v-else>
              {{ $t("message.projects.project") }}
              <br />
              <a
                @click="openProjectbyId(sprintData.project.id)"
                style="color: #5ca6de"
                >{{ sprintData.project.name }}
              </a>
            </b-col>
            <b-col md="1">
              {{ $t("message.date.start") }}<br />
              {{ localeDate(sprintData.startDate) }}
            </b-col>
            <b-col md="1">
              {{ $t("message.date.end") }}<br />
              {{ localeDate(sprintData.endDate) }}
            </b-col>
            <b-col md="1">
              {{ $t("message.projects.evolution") }}<br />
              <a>{{ sprintData.percentage.toFixed(0) }} %</a>
            </b-col>
            <b-col md="1" v-if="sprintData.tasks.length > 0">
              <b-button
                id="kanbanId"
                @click="filterKanban"
                variant="outline-primary"
              >
                <feather-icon icon="TrelloIcon" size="22" />
              </b-button>
              <b-tooltip
                target="kanbanId"
                triggers="hover"
                no-fade
                placement="bottom"
              >
                Kanban
              </b-tooltip>
            </b-col>
          </b-row>


          <b-row>
            <b-col md="12">
              <strong class="d-flex mb-0" style="gap: 0.25rem">
              <div>
                {{ $t("message.projects.tasks") }}
                ({{ sprintData.tasks.length || 0 }})
              </div>
            </strong>
            <div class="d-flex flex-wrap">
              <div v-for="(task, index) in sprintData.tasks" :key="task.id">
                <a @click="openTaskbyId(task.id)" class="d-flex" style="color: #5ca6de; gap: 0.25rem" :class="index !== 0 && 'ml-1'">
                  {{task.subject}}
                  <IconLinkInternal size="12" color="#5ca6de"/>
                  <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
                  {{ sprintData.tasks.length - 1 > index ? ', ' : '' }}
                </a>
              </div>
            </div>
            </b-col>
          </b-row>
        </div>
        <br />
        <hr class="invoice-spacing" />
      </b-card-body>
    </b-card>
    <b-card>
      <app-timeline>
        <!--Not Started-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-danger`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="AlignCenterIcon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Not Started</h6>
              <div v-if="sprintData.startDate">
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="localeDate(sprintData.startDate)"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2">{{ $t("message.sprints.sprintCreation") }}</p>
          </slot>
        </li>

        <!--Sprint Planning-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-info`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="Edit3Icon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Sprint Planning</h6>
              <div v-if="sprintData.planningDate">
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="localeDate(sprintData.planningDate)"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2" v-if="sprintData.planningDesc">
              <span v-html="sprintData.planningDesc"></span>
            </p>
            <p class="mb-2" v-else>{{ $t("message.sprints.stepNot") }}</p>
          </slot>
        </li>

        <!--Running-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-primary`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="GitCommitIcon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Running</h6>
              <div v-if="sprintData.runningDate">
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="localeDate(sprintData.runningDate)"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2" v-if="sprintData.runningDesc">
              <span v-html="sprintData.runningDesc"></span>
            </p>
            <p class="mb-2" v-else>{{ $t("message.sprints.stepNot") }}</p>
          </slot>
        </li>

        <!--Sprint Review-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-warning`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="ListIcon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Sprint Review</h6>
              <div v-if="sprintData.reviewDate">
                <small
                  v-text="localeDate(sprintData.reviewDate)"
                  class="timeline-item-time text-nowrap text-muted"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2" v-if="sprintData.reviewDesc">
              <span v-html="sprintData.reviewDesc"></span>
            </p>
            <p class="mb-2" v-else>{{ $t("message.sprints.stepNot") }}</p>
          </slot>
        </li>

        <!--Sprint Retrospective-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-secondary`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="ClipboardIcon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Sprint Retrospective</h6>
              <div v-if="sprintData.retrospectiveDate">
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="localeDate(sprintData.retrospectiveDate)"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2" v-if="sprintData.retrospectiveDesc">
              <span v-html="sprintData.retrospectiveDesc"></span>
            </p>
            <p class="mb-2" v-else>{{ $t("message.sprints.stepNot") }}</p>
          </slot>
        </li>

        <!--Finished-->
        <li
          v-bind="$attrs"
          class="timeline-item"
          :class="[`timeline-variant-success`]"
          v-on="$listeners"
        >
          <div
            class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
          >
            <feather-icon icon="AwardIcon" />
          </div>

          <slot>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Finished</h6>
              <div v-if="sprintData.status === 'Finished'">
                <small
                  class="timeline-item-time text-nowrap text-muted"
                  v-text="localeDate(sprintData.finishDate)"
                />
              </div>
              <div v-else>
                <small class="timeline-item-time text-nowrap text-muted">
                  {{ $t("message.sprints.dateNot") }}
                </small>
              </div>
            </div>
            <p class="mb-2" v-if="sprintData.finishDate">
              {{ $t("message.sprints.sprintEnd") }}
            </p>
            <p class="mb-2" v-else>{{ $t("message.sprints.stepNot") }}</p>
          </slot>
        </li>
      </app-timeline>
    </b-card>

    <b-modal
      id="modal-delete-confirmation"
      modal-class="modal-danger"
      centered
      :title="$t('delete')"
      hide-footer
    >
      <b-card-text>
        {{ $t("message.deletAcount") }}
      </b-card-text>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <b-button
          v-b-modal.modal-danger
          class="mt-2"
          variant="danger"
          :to="{ name: 'apps-sprint-list' }"
          @click="deleteSprint()"
        >
          {{ $t("message.buttons.delete") }}
        </b-button>
      </div>
    </b-modal>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BFormSpinbutton,
  BProgress,
  BProgressBar,
  BFormDatepicker,
  BCardBody,
  BTableLite,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BBadge,
  VBTooltip,
  BTooltip,
  BOverlay,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import AccessControl from "@core/utils/access-control";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import store from "@/store";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BFormTextarea,
    BCardText,
    flatPickr,
    BFormSpinbutton,
    BProgress,
    BProgressBar,
    BFormDatepicker,
    BCardBody,
    BTableLite,
    VBToggle,
    BDropdown,
    BDropdownItem,
    BBadge,
    BTooltip,
    AppTimeline,
    BOverlay,
    IconLinkInternal
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      sprintData: [],
      showOverlay: false,
      //Select
      userData: store.state.user.userData,
    };
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  async created() {
    this.showOverlay = true;
    await this.getData();
    setTimeout(() => {
      this.showOverlay = false;
    }, 1000)
  },

  methods: {
    filterKanban() {
      this.$router.push({
        name: "apps-kanban-filter",
        params: {
          projectId: this.sprintData.project.id,
          sprintId: router.currentRoute.params.id,
        },
      });
    },

    restrictions(value) {
      return AccessControl(value);
    },

    openProjectbyId(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    openTaskbyId(taskid) {
      this.$router.push({ name: "apps-task-view", params: { id: taskid } });
    },

    statusColor(status) {
      if (status === "Not Started") {
        return "danger";
      } else if (status === "Sprint Planning") {
        return "info";
      } else if (status === "Running") {
        return "primary";
      } else if (status === "Sprint Review") {
        return "warning";
      } else if (status === "Sprint Retrospective") {
        return "secondary";
      } else if (status === "Finished") {
        return "success";
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}sprint/details/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.sprintData = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.sprintData = undefined;
          }
        });
    },

    openProjectDetails(projectId) {
      this.$router.push({
        name: "apps-projects-details",
        params: { id: projectId },
      });
    },

    deleteSprint() {
      axios({
        method: "delete",
        url: `${URL_API}sprint/${this.sprintData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A sproint ${this.sprintData.subject} foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            sprintData.value = undefined;
          }
        });
    },

    gradientColorProgressBar() {
      return `background: linear-gradient(90deg, currentColor ${this.sprintData.percentage}%, rgba(0,0,0,0.1) ${this.sprintData.percentage}%); animationDelay: -${this.sprintData.percentage}s; !important`;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import "~@core/scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "~@core/scss/base/components/include"; // Components includes

// Color palettes
@import "~@core/scss/base/core/colors/palette-variables.scss";

$timeline-border-color: $border-color;

/* Generate:
  *  Apply background color to dot
  */
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== "base" {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: "";
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
